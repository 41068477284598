export default {
    types: [ "top", "bottom", "left", "right" ],
    values: [
        0,
        0.25,
        0.5,
        1,
        1.5,
        3,
    ],
};
