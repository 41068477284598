export default {
    z: 100,
    padding: 0.5,
    sizes: {
        lg: 71,
    },
    logo: {
        height: 2,
    },
    items: {
        padding: 0.75,
    },
};
